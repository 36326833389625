import {Pagination, PaginationParams, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {IInstitution, IBook, INameId, XmasPackageListEntry, XmasPackageDetails} from "../core/interfaces";
import {UUID} from "node:crypto";


export const getAllXmasPackages = (params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<XmasPackageListEntry>>('/api/dashboard/orders/xmas-packages', {
        params,
    })


export const getXmasPackageDetails = (id: string) =>
    http.get<XmasPackageDetails>(`/api/dashboard/orders/xmas-packages/${id}`)

export const replaceBook = (id: string, bookInstanceId: string, bookId: string) =>
    http.post(`/api/dashboard/orders/xmas-packages/${id}/from/${bookInstanceId}/to/${bookId}`)

export const finalizePackage = (id: string) =>
    http.put(`/api/dashboard/orders/xmas-packages/${id}/finalize`)

export const setAwbOnPackage = (id: string, awb: string) =>
    http.put(`/api/dashboard/orders/xmas-packages/${id}/awb/${awb}`)