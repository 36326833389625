import {yupResolver} from '@hookform/resolvers/yup'
import {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import * as yup from 'yup'

import {DeleteConfirmationModal} from '../../core/components/DeleteConfirmationModal'
import {MessageBox} from '../../core/components/MessageBox'
import {IBook, IBookInstance, IBookInstanceStatus, IInstitution, IInstitutionGroup} from "../../core/interfaces";
import {UUID} from "node:crypto";
import {Pagination, SimpleSearchPaginationParams} from "../../core/services/utils";
import {AxiosResponse} from 'axios';
import {map} from "lodash-es";
import {getAllInstitutions} from "../../services/institutions";
import {getAllInstitutionGroups, getAllInstitutionGroupsByInstitution} from "../../services/institutionGroups";

export interface BookInstanceModalProps {
    showCreateModal: boolean
    setShowCreateModal: (show: boolean) => void
    setEditMode: (editMode: boolean) => void
    editMode: boolean
    page: number
    size: number
    setTotalElements: (elements: number) => void
    setAllList: (categories: IBookInstance[]) => void
    bookInstanceEntity?: IBookInstance
    createCall: (id: string, data: BookInstanceModel) => Promise<AxiosResponse<IBook>>
    deleteCall: (id: UUID) => Promise<AxiosResponse<any>>
    updateCall: (bookId: string, id: UUID, data: BookInstanceModel) => Promise<AxiosResponse<IBookInstance>>
    getAllCall: (id: string, params?: SimpleSearchPaginationParams) => Promise<AxiosResponse<Pagination<IBookInstance>>>
    bookId: string,
    catalogPrice: number
}

export interface BookInstanceModeledId {
    status?: IBookInstanceStatus
    rentCounter?: number
    catalogPrice?: number
    boughtPrice?: number
    numberOfInstances?: number
    institutionId?: string
    institutionName?: string
    uniqueReadableId?: string
    institutionGroupId?: string
}

export interface InstitutionInBookInstanceModel {
    id?: string
    name?: string
    readableIdPrefix?: string
}

export interface BookInstanceModel {
    id?: string
    uniqueReadableId?: string
    status?: IBookInstanceStatus
    rentCounter?: number
    catalogPrice?: number
    boughtPrice?: number
    numberOfInstances?: number
    institution?: InstitutionInBookInstanceModel
    institutionGroupId?: string
}

export const BookInstanceModal = (
    props: BookInstanceModalProps,
): JSX.Element => {
    const {
        bookInstanceEntity,
        setShowCreateModal,
        setAllList,
        showCreateModal,
        setEditMode,
        editMode,
        page,
        size,
        bookId,
        setTotalElements,
        createCall,
        deleteCall,
        updateCall,
        getAllCall,

    } = props

    const [showDeleteConfirmationModal, setDeleteConfirmationModal] =
        useState<boolean>(false)

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [allInstitutions, setAllInstitutions] = useState<IInstitution[]>([])
    const [allGroupsForInstitution, setAllGroupsForInstitution] = useState<IInstitutionGroup[]>([])
    const resetGroups = (instId: string) => {
        var getAllParams: SimpleSearchPaginationParams = {
            page: 0,
            size: 1000
        };
        getAllInstitutionGroupsByInstitution(instId, getAllParams).then(data => setAllGroupsForInstitution(data.data.content));
    }
    useEffect(() => {
        var getAllParams: SimpleSearchPaginationParams = {
            page: 0,
            size: 1000
        };
        (async () => {
            const [allInstitutions, allGroupsForInstitution] = await Promise.all(
                [getAllInstitutions(getAllParams), []])
            setAllInstitutions(allInstitutions.data.content)
        })()



        if (editMode) {
            setValue('status', bookInstanceEntity?.status)
            setValue('rentCounter', bookInstanceEntity?.rentCounter)
            setValue('catalogPrice', bookInstanceEntity?.catalogPrice)
            setValue('boughtPrice', bookInstanceEntity?.boughtPrice)
            setValue('institutionId', bookInstanceEntity?.institution?.id)
            setValue('institutionGroupId', bookInstanceEntity?.institutionGroupId)
            setValue('institutionName', bookInstanceEntity?.institution?.name)
            setValue('uniqueReadableId', bookInstanceEntity?.uniqueReadableId)
            if (bookInstanceEntity?.institution?.id) {
                resetGroups(bookInstanceEntity?.institution?.id)
            }
        } else {
            setValue('rentCounter', 0);
            setValue('uniqueReadableId', '')
            setValue('status', 'IN_DEPOSIT')
            setValue('catalogPrice', props.catalogPrice)
            setValue('institutionId', allInstitutions.find((inst) => inst.name === 'HOME')?.id)
        }
    }, [bookInstanceEntity, editMode, props.catalogPrice])

    const schema = yup.object().shape({
        status: yup.mixed<IBookInstanceStatus>().oneOf([
            'IN_DEPOSIT',
            'RESERVED',
            'RENTED',
            'RETRIEVED'
        ]),
        rentCounter: yup.number().optional().nullable(),
        catalogPrice: yup.number().optional(),
        boughtPrice: yup.number(),
        numberOfInstances: yup.number(),
        institutionId: yup.string().uuid().required(),
        institutionGroupId: yup.string().notRequired().optional().nullable(),
        institutionName: yup.string().optional().nullable(),
        uniqueReadableId: yup.string().optional().nullable(),
    })

    const {
        handleSubmit,
        register,
        formState: {errors, dirtyFields},
        reset,
        setValue,
        getValues,
        resetField
    } = useForm<BookInstanceModeledId>({
        mode: 'onChange',
        resolver: yupResolver<BookInstanceModeledId>(schema),
        defaultValues: {
            status: 'IN_DEPOSIT',
            rentCounter: 0,
            catalogPrice: 0,
            boughtPrice: 0,
            numberOfInstances: 1,
            institutionId: '',
            institutionName: '',
            uniqueReadableId: ''
        },
    })

    const handleDelete = () => {
        // prettier-ignore
        (async () => {
            try {
                if (bookInstanceEntity) {
                    const {status} = await deleteCall(bookInstanceEntity?.id)
                    const response = await getAllCall(bookId, {
                        page: page - 1,
                        size,
                    })
                    setTotalElements(response.data.totalElements)
                    setAllList(response.data.content)
                    if (status === 204 && response.status === 200) {
                        setIsError(false)
                        setShowMessageBox(true)
                        setMessage('Delete Successful')
                    }
                }
            } catch (e) {
                if (e instanceof Error) {
                    setIsError(true)
                    setShowMessageBox(true)
                    setMessage(e.message)
                } else {
                    console.error(e)
                }
            }
        })()
        handleClose()
    }

    const handleClose = () => {
        setShowCreateModal(false)
        setEditMode(false)
        setAllGroupsForInstitution([])
        reset()
    }

    const submit = (values: BookInstanceModeledId) => {
        // prettier-ignore
        (async () => {
            try {
                console.log(values)
                const data: BookInstanceModel = {
                    status: values.status,
                    rentCounter: values.rentCounter,
                    catalogPrice: values.catalogPrice,
                    boughtPrice: values.boughtPrice,
                    numberOfInstances: values.numberOfInstances,
                    institution: {
                        id: values.institutionId
                    },
                    institutionGroupId: values.institutionGroupId == '1' ? null : values.institutionGroupId
                }
                if (bookInstanceEntity) {
                    const {status} = await updateCall(bookId, bookInstanceEntity.id, data)
                    const response = await getAllCall(bookId, {
                        page: page - 1,
                        size,
                    })
                    setAllList(response.data.content)
                    if (status === 200 && response.status === 200) {
                        setIsError(false)
                        setShowMessageBox(true)
                        setMessage('Update Successful')
                    }
                } else {
                    const {status} = await createCall(bookId, data)
                    const response = await getAllCall(bookId, {
                        page: page - 1,
                        size,
                    })
                    setTotalElements(response.data.totalElements)
                    setAllList(response.data.content)
                    if (status === 200 && response.status === 200) {
                        setIsError(false)
                        setShowMessageBox(true)
                        setMessage('Create Successful')
                    }
                }
            } catch (e) {
                if (e instanceof Error) {
                    setIsError(true)
                    setShowMessageBox(true)
                    setMessage(e.message)
                } else {
                    console.error(e)
                }
            }
        })()
        handleClose()
    }

    return (
        <>
            <Modal
                show={showCreateModal}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editMode
                            ? `Edit  ${bookInstanceEntity?.uniqueReadableId}`
                            : `Add New`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="iname-modal-form" onSubmit={handleSubmit(submit)}>
                        <Form.Group className="d-flex flex-column align-items-start gap-3">
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Rent Counter
                                </Form.Label>
                                <Form.Control
                                    disabled={true}
                                    defaultValue={editMode ? bookInstanceEntity.rentCounter : 0}
                                    isInvalid={!!errors?.rentCounter?.message}
                                    type="number"
                                    placeholder="Enter RentCounter"
                                />

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Bought Price
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.boughtPrice?.message}
                                    {...register('boughtPrice')}
                                    type="number"
                                    step={0.01}
                                    placeholder="Enter Bought Price"
                                />

                                {editMode ? <></> :
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    No of books
                                </Form.Label>
                                }
                                {editMode ? <></> :
                                <Form.Control
                                    isInvalid={!!errors?.numberOfInstances?.message}
                                    {...register('numberOfInstances')}
                                    type="number"
                                    step={1}
                                    placeholder="Enter Number of Books"
                                />
                                }

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Catalog Price
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.catalogPrice?.message}
                                    {...register('catalogPrice')}
                                    type="number"
                                    step={0.01}
                                    placeholder="Enter Catalog Price"
                                />
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Status
                                </Form.Label>
                                <Form.Select className="d-flex flex-column mb-0"
                                             defaultValue={editMode ? bookInstanceEntity.status : 'IN_DEPOSIT'}
                                             isInvalid={!!errors?.status?.message}
                                             onChange={(e) => {
                                                 const val = e.target.value
                                                 setValue('status', val as IBookInstanceStatus, {shouldDirty: true})
                                             }}
                                >
                                    <option disabled value={''}>
                                        Select Status
                                    </option>
                                    {map([
                                        'IN_DEPOSIT',
                                        'RESERVED',
                                        'RENTED',
                                        'RETRIEVED'
                                    ], (language) => (
                                        <option id={language} key={language} value={language}>
                                            {language}
                                        </option>
                                    ))}
                                </Form.Select>

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Institution
                                </Form.Label>
                                <Form.Select className="d-flex flex-column mb-0"
                                             isInvalid={!!errors?.status?.message}
                                             onChange={(e) => {
                                                 const val = e.target.value
                                                 setValue('institutionId', val, {shouldDirty: true})
                                                 setValue('institutionName', e.target.name, {shouldDirty: true})
                                                 resetGroups(val);
                                             }}
                                >
                                    <option disabled value={''} selected={editMode == false}>
                                        Select Institution
                                    </option>
                                    {map(allInstitutions, (institution) => (
                                        <option id={institution.id} key={institution.id} value={institution.id}
                                                selected={(editMode == true && institution.id == bookInstanceEntity.institution.id)
                                        || (editMode === false && institution.name === 'HOME')}>
                                            {institution.name}
                                        </option>
                                    ))}
                                </Form.Select>

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Group
                                </Form.Label>
                                <Form.Select className="d-flex flex-column mb-0"
                                             isInvalid={!!errors?.status?.message}
                                             onChange={(e) => {
                                                 const val = e.target.value
                                                 setValue('institutionGroupId', val, {shouldDirty: true})
                                             }}
                                >
                                    <option value={'1'}
                                            selected={editMode == false || getValues('institutionGroupId') == null}>
                                        No Group
                                    </option>
                                    {map(allGroupsForInstitution, (group) => (
                                        <option id={group.id} key={group.id} value={group.id}
                                                selected={editMode == true && group.id == bookInstanceEntity.institutionGroupId}>
                                            {group.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editMode && (
                        <Button
                            onClick={handleDelete}
                            style={{color: '#FFFFFF'}}
                            className="btn btn-danger me-auto "
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        onClick={handleClose}
                        className="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-primary"
                        form="iname-modal-form"
                        type="submit"
                        disabled={Object.keys(dirtyFields).length === 0}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                showDeleteConfirmationModal={showDeleteConfirmationModal}
                setDeleteConfirmationModal={setDeleteConfirmationModal}
                deleteTitle={'Delete'}
                deleteFunc={handleDelete}
            />
            <MessageBox
                message={message}
                setShowMessageBox={setShowMessageBox}
                showMessageBox={showMessageBox}
                isError={isError}
            />
        </>
    )
}
