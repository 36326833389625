import React from 'react';

type Props = {};

const OrdersIndex = (props: Props) => {
  return (
    <div>InstitutionsIndex</div>
  );
};

export default OrdersIndex;